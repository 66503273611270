<template>
  <div>
    <el-dialog
      width="30%"
      :title="form.id ? '编辑招聘人员情况' : '新增招聘人员情况'"
      :visible="dialogFormVisible"
      :before-close="cancelFn"
    >
      <el-form :model="form" :rules="rules" ref="form" :loading="loading">
        <el-form-item prop="department" label="所属部门" :label-width="formLabelWidth">
          <el-select v-model="form.department" placeholder="请选择部门">
            <el-option label="总经办" value="总经办"></el-option>
            <el-option label="软件开发部" value="软件开发部"></el-option>
            <el-option label="测绘数据部" value="测绘数据部"></el-option>
            <el-option label="综合部" value="综合部"></el-option>
            <el-option label="人事部" value="人事部"></el-option>
            <el-option label="行政部" value="行政部"></el-option>
            <el-option label="财务部" value="财务部"></el-option>
            <el-option label="商务部" value="商务部"></el-option>
            <el-option label="分公司" value="分公司"></el-option>
            <el-option label="报批部" value="报批部"></el-option>
            <el-option label="规划部" value="规划部"></el-option>
            <el-option label="计划经营部" value="计划经营部"></el-option>
            <el-option label="北京分公司" value="北京分公司"></el-option>
            <el-option label="鸿森林业" value="鸿森林业"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="kqgws" label="空缺岗位数" :label-width="formLabelWidth">
          <el-input v-model="form.kqgws"></el-input>
        </el-form-item>
        <el-form-item prop="jlsxs" label="简历筛选数" :label-width="formLabelWidth">
          <el-input v-model="form.jlsxs"></el-input>
        </el-form-item>
        <el-form-item prop="csrs" label="初试参加人数" :label-width="formLabelWidth">
          <el-input v-model="form.csrs"></el-input>
        </el-form-item>
        <el-form-item prop="fsrs" label="复试参加人数" :label-width="formLabelWidth">
          <el-input v-model="form.fsrs"></el-input>
        </el-form-item>
        <el-form-item prop="fstgrs" label="复试通过人数" :label-width="formLabelWidth">
          <el-input v-model="form.fstgrs"></el-input>
        </el-form-item>
        <el-form-item prop="dgrs" label="到岗人数" :label-width="formLabelWidth">
          <el-input v-model="form.dgrs"></el-input>
        </el-form-item>
        <el-form-item prop="submitDate" label="当前时间" :label-width="formLabelWidth">
          <el-input required="required" v-model="form.submitDate" type="date"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cancelFn">取 消</el-button>
        <el-button type="primary" @click="addFn">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  props: {
    dialogFormVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      formLabelWidth: '110px',
      form: {
        csrs: '',
        department: '',
        dgrs: '',
        fsrs: '',
        fstgrs: '',
        jlsxs: '',
        kqgws: '',
        lyb: '',
        id: '',
        pageNow: '1',
        pageSize: '1',
        submitDate: this.getNow(),
      },
      rules: {
        department: [{ required: true, message: '请选择部门', trigger: 'change' }],
        csrs: [
          { required: true, message: '请输入初试人数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        kqgws: [
          { required: true, message: '请输入空缺岗位数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        jlsxs: [
          { required: true, message: '请输入简历筛选数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        fsrs: [
          { required: true, message: '请输入复试人数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        fstgrs: [
          { required: true, message: '请输入复试通过人数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        dgrs: [
          { required: true, message: '请输入到岗人数', trigger: 'blur' },
          {
            required: true,
            validator: this.integerP,
            message: '请输入整数',
            trigger: 'change',
          },
        ],
        submitDate: [
          {
            required: true,
            message: '请选择日期',
            trigger: 'change',
          },
        ],
      },
    }
  },

  methods: {
    Echo(val) {
      // 注:时间戳转时间（ios手机NaN）
      val.submitDate = getTime(val.submitDate)
      function getTime(nS) {
        var date = new Date(parseInt(nS))
        var year = date.getFullYear()
        var mon = date.getMonth() + 1
        var day = date.getDate()
        if (mon < 10) {
          mon = '0' + mon
        }
        if (day < 10) {
          day = '0' + day
        }
        return year + '-' + mon + '-' + day
      }
      this.form = JSON.parse(JSON.stringify(val))
    },
    getNow() {
      this.loading = true
      var date = new Date()
      var year = date.getFullYear()
      var mon = date.getMonth() + 1
      var day = date.getDate()
      if (mon < 10) {
        mon = '0' + mon
      }
      if (day < 10) {
        day = '0' + day
      }
      this.loading = false
      return year + '-' + mon + '-' + day
    },
    cancelFn() {
      this.form = {
        csrs: '',
        department: '',
        dgrs: '',
        fsrs: '',
        fstgrs: '',
        jlsxs: '',
        kqgws: '',
        lyb: '',
        id: '',
        pageNow: '1',
        pageSize: '1',
        submitDate: this.getNow(),
      }
      this.$refs.form.resetFields()
      this.$emit('update')
      this.$emit('update:dialogFormVisible', false)
    },
    addFn() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          this.form.lyb = ((this.form.dgrs / this.form.kqgws) * 100).toFixed(2)
          if (this.form.id !== '') {
            const res = await this.$api.user.updateRecruitmentInfo(this.form)
            this.$message({
              message: '编辑成功!',
              type: 'success',
            })
          } else {
            const res = await this.$api.user.addRecruitmentInfo(this.form)
            this.$message({
              message: '新增成功!',
              type: 'success',
            })
          }
          this.$emit('update')
          this.$emit('update:dialogFormVisible', false)
          this.form = {
            csrs: '',
            department: '',
            dgrs: '',
            fsrs: '',
            fstgrs: '',
            jlsxs: '',
            kqgws: '',
            lyb: '',
            id: '',
            pageNow: '1',
            pageSize: '1',
            submitDate: this.getNow(),
          }
        }
      })
    },
    integerP(rule, value, callback) {
      if (value && !/^[0-9]\d*$/.test(value)) {
        callback(new Error('只能填写正整数'))
      } else {
        callback()
      }
    },
  },
}
</script>

<style></style>
